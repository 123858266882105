@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700;900&family=Source+Sans+Pro:wght@400;700&display=swap");
:root {
    --primary: #f2b807;
    --second: #f28f16;
    --third: #f5db13;
    --dark: #212121;
    --white: #f6f7f9;
    --danger: #d93e30;
    --normal: #76aadb;
    --fire: #f76545;
    --fighting: var(--fire);
    --dragon: var(--fire);
    --water: #a2cff0;
    --ice: var(--water);
    --grass: #70a83b;
    --bug: var(--grass);
    --rock: #a1a1a1;
    --steel: var(--rock);
    --darkType: var(--rock);
    --electric: #f7c545;
    --ground: #9b897b;
    --poison: #a974bc;
    --psychic: var(--poison);
    --fairy: var(--poison);
    --ghost: var(--poison);
}
html {
    box-sizing: border-box;
}
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
li {
    list-style-type: none;
}
a {
    text-decoration: none;
    color: var(--dark);
}
body {
    font-family: "Karla", sans-serif;
    font-size: 16px;
    color: var(--dark);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Karla", sans-serif;
}

p,
a {
    font-family: "Source Sans Pro", sans-serif;
}
.container {
    width: 100%;
    margin: 0 auto;
}
#root,
#overlays {
    position: relative;
}

.fire,
.fighting,
.dragon {
    background-color: var(--fire);
}
.fire.gradient,
.fighting.gradient,
.dragon.gradient {
    background: linear-gradient(270deg, #b23327, #d93e30);
}
.fire.darkGradient,
.fighting.darkGradient,
.dragon.darkGradient {
    background: linear-gradient(180deg, #732119, #d93e30);
}

.water,
.ice {
    background-color: var(--water);
}
.water.gradient,
.ice.gradient {
    background: linear-gradient(270deg, #5bc7fa, #35baff);
}
.water.darkGradient,
.ice.darkGradient {
    background: linear-gradient(180deg, #1e749c, #35baff);
}
.normal,
.flying {
    background-color: var(--normal);
}
.normal.gradient,
.flying.gradient {
    background: linear-gradient(270deg, #76aadb, #4192df);
}
.normal.darkGradient,
.flying.darkGradient {
    background: linear-gradient(180deg, #2f87da, #4192df);
}
.grass,
.bug {
    background-color: var(--grass);
}
.grass.gradient,
.bug.gradient {
    background: linear-gradient(270deg, #64d368 0.15%, #64d368 70.88%);
}
.grass.darkGradient,
.bug.darkGradient {
    background: linear-gradient(180deg, #4c9c4e, #64d368);
}
.rock,
.steel,
.dark {
    background-color: var(--rock);
}
.rock.gradient,
.steel.gradient,
.dark.gradient {
    background: linear-gradient(270deg, #a1a1a1, #504f4f);
}
.rock.darkGradient,
.steel.darkGradient,
.dark.darkGradient {
    background: linear-gradient(180deg, #5a5959, #7c7b7b);
}
.electric {
    background-color: var(--electric);
}
.electric.gradient {
    background: linear-gradient(270deg, #f5db13, #f2b807);
}
.electric.darkGradient {
    background: linear-gradient(180deg, #f0bc22, #f2b807);
}
.ground {
    background-color: var(--ground);
}
.ground.gradient {
    background: linear-gradient(270deg, #9b897b, #9c7d65);
}
.ground.darkGradient {
    background: linear-gradient(180deg, #d39668, #9c7d65);
}
.poison,
.psychic,
.fairy,
.ghost {
    background-color: var(--poison);
}
.poison.gradient,
.psychic.gradient,
.fairy.gradient,
.ghost.gradient {
    background: linear-gradient(270deg, #f89eae, #f4b5c1);
}
.poison.darkGradient,
.psychic.darkGradient,
.fairy.darkGradient,
.ghost.darkGradient {
    background: linear-gradient(180deg, #f78398, #f4b5c1);
}
