@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700;900&family=Source+Sans+Pro:wght@400;700&display=swap);
:root {
    --primary: #f2b807;
    --second: #f28f16;
    --third: #f5db13;
    --dark: #212121;
    --white: #f6f7f9;
    --danger: #d93e30;
    --normal: #76aadb;
    --fire: #f76545;
    --fighting: var(--fire);
    --dragon: var(--fire);
    --water: #a2cff0;
    --ice: var(--water);
    --grass: #70a83b;
    --bug: var(--grass);
    --rock: #a1a1a1;
    --steel: var(--rock);
    --darkType: var(--rock);
    --electric: #f7c545;
    --ground: #9b897b;
    --poison: #a974bc;
    --psychic: var(--poison);
    --fairy: var(--poison);
    --ghost: var(--poison);
}
html {
    box-sizing: border-box;
}
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
li {
    list-style-type: none;
}
a {
    text-decoration: none;
    color: #212121;
    color: var(--dark);
}
body {
    font-family: "Karla", sans-serif;
    font-size: 16px;
    color: #212121;
    color: var(--dark);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Karla", sans-serif;
}

p,
a {
    font-family: "Source Sans Pro", sans-serif;
}
.container {
    width: 100%;
    margin: 0 auto;
}
#root,
#overlays {
    position: relative;
}

.fire,
.fighting,
.dragon {
    background-color: #f76545;
    background-color: var(--fire);
}
.fire.gradient,
.fighting.gradient,
.dragon.gradient {
    background: linear-gradient(270deg, #b23327, #d93e30);
}
.fire.darkGradient,
.fighting.darkGradient,
.dragon.darkGradient {
    background: linear-gradient(180deg, #732119, #d93e30);
}

.water,
.ice {
    background-color: #a2cff0;
    background-color: var(--water);
}
.water.gradient,
.ice.gradient {
    background: linear-gradient(270deg, #5bc7fa, #35baff);
}
.water.darkGradient,
.ice.darkGradient {
    background: linear-gradient(180deg, #1e749c, #35baff);
}
.normal,
.flying {
    background-color: #76aadb;
    background-color: var(--normal);
}
.normal.gradient,
.flying.gradient {
    background: linear-gradient(270deg, #76aadb, #4192df);
}
.normal.darkGradient,
.flying.darkGradient {
    background: linear-gradient(180deg, #2f87da, #4192df);
}
.grass,
.bug {
    background-color: #70a83b;
    background-color: var(--grass);
}
.grass.gradient,
.bug.gradient {
    background: linear-gradient(270deg, #64d368 0.15%, #64d368 70.88%);
}
.grass.darkGradient,
.bug.darkGradient {
    background: linear-gradient(180deg, #4c9c4e, #64d368);
}
.rock,
.steel,
.dark {
    background-color: #a1a1a1;
    background-color: var(--rock);
}
.rock.gradient,
.steel.gradient,
.dark.gradient {
    background: linear-gradient(270deg, #a1a1a1, #504f4f);
}
.rock.darkGradient,
.steel.darkGradient,
.dark.darkGradient {
    background: linear-gradient(180deg, #5a5959, #7c7b7b);
}
.electric {
    background-color: #f7c545;
    background-color: var(--electric);
}
.electric.gradient {
    background: linear-gradient(270deg, #f5db13, #f2b807);
}
.electric.darkGradient {
    background: linear-gradient(180deg, #f0bc22, #f2b807);
}
.ground {
    background-color: #9b897b;
    background-color: var(--ground);
}
.ground.gradient {
    background: linear-gradient(270deg, #9b897b, #9c7d65);
}
.ground.darkGradient {
    background: linear-gradient(180deg, #d39668, #9c7d65);
}
.poison,
.psychic,
.fairy,
.ghost {
    background-color: #a974bc;
    background-color: var(--poison);
}
.poison.gradient,
.psychic.gradient,
.fairy.gradient,
.ghost.gradient {
    background: linear-gradient(270deg, #f89eae, #f4b5c1);
}
.poison.darkGradient,
.psychic.darkGradient,
.fairy.darkGradient,
.ghost.darkGradient {
    background: linear-gradient(180deg, #f78398, #f4b5c1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_navbar__YI3Qt {
    display: none;
}
.style_header__MlSQ_ {
    width: 100%;
    min-height: 3.5rem;
    background-color: var(--third);
    box-shadow: 0px 4px 16px #011c4030;
    position: relative;
}
.style_container__3TbXE {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 1.0625rem 1.625rem;
}
.style_logo__d1_Sa {
    width: 100%;
    max-width: 5rem;
}

.style_navMenuBtn__3ST0W {
    border: none;
    background: none;
    cursor: pointer;
}
.style_navMenuBtn__3ST0W img {
    max-width: 2rem;
}

@media (min-width: 768px) {
    .style_navbar__YI3Qt {
        display: block;
    }
    .style_navMenuBtn__3ST0W {
        display: none !important;
    }
    .style_logo__d1_Sa {
        max-width: 7.5625rem;
    }
}
@media (min-width: 1440px) {
    .style_container__3TbXE {
        max-width: 74.125rem;
    }
    .style_logo__d1_Sa {
        max-width: 9.448125rem;
    }
}

/* .nav {
    display: none;
} */
.style_nav__qg7NI ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.style_nav__qg7NI ul li {
    margin: 1rem;
}

.style_nav__qg7NI ul li a {
    font-size: 1.6875rem;
}

.style_nav__qg7NI ul li > a:focus,
.style_nav__qg7NI ul li > a:hover,
.style_active__1EmzT {
    border-bottom: 0.1875rem solid var(--dark);
}

@media (min-width: 768px) {
    .style_nav__qg7NI {
        display: flex;
        justify-content: center;
    }
    .style_nav__qg7NI ul {
        flex-direction: row;
    }
    .style_nav__qg7NI ul li a {
        font-size: 1.1875rem;
    }
}
@media (min-width: 1440px) {
    .style_nav__qg7NI ul li a {
        font-size: 1.5625rem;
    }
}

.MobileNav_backdrop__1FNzU {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
}
.MobileNav_mobile_nav__3gbwl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 3.125rem 4.6875rem;
    border-radius: 0px 0px 16px 16px;
    border: none;
    width: 100vw;
    background: linear-gradient(var(--third), var(--primary));
    z-index: 10;
}
.MobileNav_logo__34nhU {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 8.625rem;
}
.MobileNav_mobileNavMenuBtn__2cuq8 {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1.625rem;
}
.MobileNav_mobileNavMenuBtn__2cuq8 img {
    max-width: 2rem;
}

.styles_backdrop__2c_W9 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}
.styles_content__19PA6 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.styles_content__19PA6 p {
    text-align: center;
    font-weight: 700;
    font-size: 1.4375rem;
    margin: 1.25rem;
    color: var(--white);
}

.styles_loading_img__2PRB9 {
    width: 30px;
    -webkit-animation: styles_bounce__2SuQH 0.5s;
            animation: styles_bounce__2SuQH 0.5s;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
            animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}
@-webkit-keyframes styles_bounce__2SuQH {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 100px, 0);
                transform: translate3d(0, 100px, 0);
    }
}
@keyframes styles_bounce__2SuQH {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 100px, 0);
                transform: translate3d(0, 100px, 0);
    }
}

