.navbar {
    display: none;
}
.header {
    width: 100%;
    min-height: 3.5rem;
    background-color: var(--third);
    box-shadow: 0px 4px 16px #011c4030;
    position: relative;
}
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 1.0625rem 1.625rem;
}
.logo {
    width: 100%;
    max-width: 5rem;
}

.navMenuBtn {
    border: none;
    background: none;
    cursor: pointer;
}
.navMenuBtn img {
    max-width: 2rem;
}

@media (min-width: 768px) {
    .navbar {
        display: block;
    }
    .navMenuBtn {
        display: none !important;
    }
    .logo {
        max-width: 7.5625rem;
    }
}
@media (min-width: 1440px) {
    .container {
        max-width: 74.125rem;
    }
    .logo {
        max-width: 9.448125rem;
    }
}
